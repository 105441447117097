// Drawer Menu
const baseContents = document.getElementById( 'baseContents' );
const mainContents = document.getElementById( 'mainContents' );
// バーガーメニュー
const burgerObj = '#js-burger, .burger-icon, #js-burger span';
// メニューオープン時につける class
const openMenuClass = 'side-menu-open';
// navigation Child Menu
const child_trigger = '.is-child-menu';


/** initialize */
document.addEventListener('DOMContentLoaded', function(){
  // スクロールバーの装飾
  $('nav').mCustomScrollbar({ theme: 'minimal-dark' });
  // メニュー開閉イベント
  mainContents.addEventListener('click', function( event ){
    if ( ! matches( event.target, burgerObj ) && $('body').hasClass( openMenuClass ) ) {
      document.body.classList.remove( openMenuClass );
    } else if ( matches( event.target, burgerObj ) ) {
      document.body.classList.toggle( openMenuClass );
    }
  });
  // navigation Child Menu
  $( child_trigger ).children('a').on('click', function( event ){
    event.preventDefault();
    $(this).toggleClass('child-menu-open').next('.side-navigation-child').slideToggle(200).end().parent(child_trigger).siblings(child_trigger).children('a').removeClass('child-menu-open').next('.side-navigation-child').slideUp(200);

  });

  // burger
  //burger();
  // pagetop
  pagetop();
  // accordion
  accordion();
  // tab
  tab();
});



/**
***  jQuery .is() -> JavaScript  */
const matches = function( el, selector ) {
  return ( el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector ).call( el, selector );
};



/**
***  burger  */
const burger = () => {
  const burger = document.getElementById( 'js-burger' )
  //const openMenuClass = 'open-menu'

  if ( burger != null ) {
    burger.addEventListener('click', function () {
      document.body.classList.toggle(openMenuClass)
    })
  }
}



/**
***  pagetop  */
const pagetop = () => {
  const pagetop = document.getElementById('js-pagetop')
  const pagetopActiveClass = 'active'

  if ( pagetop != null ) {
    window.addEventListener('scroll', function (e) {
      if (window.scrollY > 100) {
        pagetop.classList.add(pagetopActiveClass)
      } else {
        pagetop.classList.remove(pagetopActiveClass)
      }
    })
    pagetop.addEventListener('click', function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    })
  }
}



/**
***  accordion  */
const accordion = () => {
  // Cross-browser way to get the computed height of a certain element.
  const getStyle = (el, styleProp) => {
    let value, defaultView = ( el.ownerDocument || document ).defaultView;
    // W3C standard way:
    if ( defaultView && defaultView.getComputedStyle ) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if ( el.currentStyle ) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
        return letter.toUpperCase();
      });
      value = el.currentStyle[styleProp];
      // convert other units to pixels on IE
      if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function(value) {
          var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
          el.runtimeStyle.left = el.currentStyle.left;
          el.style.left = value || 0;
          value = el.style.pixelLeft + "px";
          el.style.left = oldLeft;
          el.runtimeStyle.left = oldRsLeft;
          return value;
        })(value);
      }
      return value;
    }
  }
  // アコーディオン処理
  const acc = document.getElementsByClassName('js-accordion-trigger');
  let i;
  // ページを開いた時にアコーディオンを開いた状態にしたいときの処理
  //const firstAccordion = acc[0];
  //const firstPanel = firstAccordion.nextElementSibling;
  //firstAccordion.classList.add('active');
  //firstPanel.style.maxHeight = firstPanel.scrollHeight + 'px';

  // Add onclick listener to every accordion element
  for ( i = 0; i < acc.length; i++ ) {
    acc[i].addEventListener('click', function () {
      // For toggling purposes detect if the clicked section is already 'active'
      const isActive = this.classList.contains('active');
      // Close all accordions
      const allAccordions = document.getElementsByClassName('js-accordion-trigger');
      for ( let j = 0; j < allAccordions.length; j++) {
        // Remove active class from section header
        allAccordions[j].classList.remove('active');
        // Remove the max-height class from the panel to close it
        const panel = allAccordions[j].nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');

        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        }
      }
      // Toggle the clicked section using a ternary operator
      isActive ? this.classList.remove('active') : this.classList.add('active');
      // Toggle the panel element
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    })
  }
}



/**
***  tab  */
const tab = () => {
  const tabs = document.getElementsByClassName('js-tab')
  const allContents = document.getElementsByClassName('js-contents')
  const currentClassName = 'current'
  const currentViewClassName = 'current-view'
  //
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function () {

      // データ属性を取得する
      const view = this.dataset.view
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName)

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabs.length; j++ ){
          tabs[j].classList.remove(currentClassName)
        }
        this.classList.add(currentClassName)
      }

      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName)
      }
      //
      document.getElementById(view).classList.add(currentViewClassName)
    })
  }
}
